import * as React from 'react';
import { useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { createAdvancePayEntry } from '../features/advancePay/advancePayEntrySlice';
import { userId } from '../utils/liff';
import { useAppDispatch } from '../app/hooks';
import { MobileDatePicker } from '@mui/x-date-pickers';
import liff from '@line/liff';

// @formatter:off

class AdvancePayEntryFormProps {
  profile: any;
  created: boolean | undefined;
  branch: string | undefined;
  entry: any;
  edit: boolean | undefined;
}

const AdvancePayEntryForm: React.FC<AdvancePayEntryFormProps> = ({
  profile,
  created,
  branch,
  entry,
  edit,
}) => {
  //interface formName {
  //  userName: string;
  //  firstname: string;
  //  staffCode: string;
  //  advancePayType: string;
  //  advancePayBranch: string;
  //}
  //console.log('date:' + new Date().toISOString());
  //日付
  const d = new Date();
  const year = d.getFullYear();
  const month = ('0' + (d.getMonth() + 1)).slice(-2);
  const day = ('0' + d.getDate()).slice(-2);
  //値の初期値設定
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      ready: 'no',
      userBranch: profile.userBranch, //支店
      appDate: year + '-' + month + '-' + day, //申込日
      staffCode: profile.staffCode, //スタッフコード
      userName: profile.lastname + '' + profile.firstname, //ユーザー名
      appAgree: '', //項目に同意
      contractAgree: '', //契約書に同意
      bankAccountAgree: '', //前給給与の振り込み先口座確認
      appAgree01: false,
      appAgree02: false,
      appAgree03: false,
      appAgree04: false,
      appAgree05: false,
      appAgree06: false,
      appAgree07: false,
      appAgree08: false,
      isContractAgreePopUped: false,
      ContractAgree01: false,
      bankAccountAgree01: false,
    },
    errors: {},
    touched: {},
  });

  //branch = 'inhouse';

  console.log('entry:' + entry);
  console.log(edit);
  console.log(formState);
  console.log(branch);

  //入力フォームの値をセット
  const handleChange = (event: any, field: any, value: any) => {
    // event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [field]: value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  //エラー設定
  const errorMessage: string[] = [];
  const [viewMessage, setViewMessage] = React.useState<Element | object>();

  //ページ管理設定
  const steps = ['1', '2', '3', '4', '5', '6'];
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  //データ送信
  const dispatch = useAppDispatch();
  const [creating, setCreating] = React.useState(false);
  const submit = () => {
    setopenConfirmPost(false);
    setCreating(true);
    dispatch(
      createAdvancePayEntry({
        userId: userId(),
        values: formState.values,
      }),
    );
  };

  if (created && activeStep !== 5) {
    //if (created) {
    setCreating(false);
    setActiveStep(5);
  }

  //ハンドル操作(ダイアログオープン/クローズ)
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };

  const [openConfirmPost, setopenConfirmPost] = React.useState(false);
  const handleCloseConfirmPost = () => {
    setopenConfirmPost(false);
  };

  const [openContract, setOpenContract] = React.useState(false);
  const handleCloseContract = () => {
    handleChange(event, 'isContractAgreePopUped', true);
    setOpenContract(false);
  };

  //ハンドル操作(次ページへの遷移)
  const handleNext = () => {
    const specialAdd = 0;

    //エラーチェック
    if (activeStep === 1) {
      if (!formState.values.userBranch) {
        setOpen(true);
        return;
      }
    }
    if (activeStep === 2) {
      if (!formState.values.appDate) {
        errorMessage.push('申込日を入力してください。');
      }
      if (!formState.values.staffCode) {
        errorMessage.push('スタッフコードを入力してください。');
      } else if (String(formState.values.staffCode).length !== 8) {
        errorMessage.push('スタッフコードは8桁で入力してください。');
      }
      if (!formState.values.userName) {
        errorMessage.push('名前を入力してください。');
      }

      //チェックしていない項目がある。
      if (
        formState.values.appAgree01 === false ||
        formState.values.appAgree02 === false ||
        formState.values.appAgree03 === false ||
        formState.values.appAgree04 === false ||
        formState.values.appAgree05 === false ||
        formState.values.appAgree06 === false ||
        formState.values.appAgree07 === false ||
        formState.values.appAgree08 === false
      ) {
        handleChange(event, 'appAgree', '');
        errorMessage.push('選択されていない項目があります。');
      } else {
        handleChange(event, 'appAgree', 'すべて同意する');
      }

      if (formState.values.ContractAgree01 === false) {
        handleChange(event, 'contractAgree', '');
        errorMessage.push(
          '前給契約書を確認し合意の場合は項目を選択してください。',
        );
      } else {
        handleChange(event, 'contractAgree', '同意する');
      }
    }

    if (activeStep === 3) {
      if (formState.values.bankAccountAgree01 === false) {
        handleChange(event, 'bankAccountAgree', '');
        errorMessage.push('選択されていない項目があります。');
      } else {
        handleChange(event, 'bankAccountAgree', '同意する');
      }
    }

    const listMessage = errorMessage.map((message) => (
      <span>
        {message} <br />
      </span>
    ));
    setViewMessage(listMessage);

    if (errorMessage.length > 0) {
      setOpen2(true);
      return;
    }

    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1 + specialAdd;
    setActiveStep(newActiveStep);
    window.scrollTo({
      top: 0,
    });
  };

  //ハンドル操作(前ページへの遷移)
  const handleBack = () => {
    const specialAdd = 0;

    setActiveStep((prevActiveStep) => prevActiveStep - 1 - specialAdd);
    window.scrollTo({
      top: 0,
    });
  };

  let branches;

  if (branch === 'dr') {
    branches = [
      '',
      'DR北関東支店(DR小山課/DR高崎課)',
      'DR東京・千葉支店(DR新宿課/DR船橋課)',
      'DR埼玉支店(DRさいたま課/DR所沢課)',
      'DR神奈川支店(DR横浜課/DR厚木課)',
      'DR名阪支店(名古屋課/大阪課)',
    ];
  } else if (branch === 'inhouse') {
    branches = [
      '',
      '宮城ニコン（戸倉）',
      '宮城ニコン（蔵王）',
      '東北電力（契約センター）',
      '東北電力（料金事務センター）',
      'プルデンシャル生命',
      'NTT',
      '仙台ニコン',
      'マーレエンジンコンポーネンツジャパン山形工場',
      'ディーアンドエム事業所',
      'リコー事業所',
      'キヤノンメディカルシステムズ',
      '電興製作所',
      'JAうつのみや',
      'パナソニック',
      'ミツトヨ',
      'パナソニック住宅設備',
      'BOSCH',
      '三菱重工パワー精密鋳造事業所',
      'SII',
      'ゼブラ',
      'ソニーMS',
      '日立GLS',
      'MonotaRO',
      'デンソーテン',
      '宮崎日機装オフィス',
      'P&G高崎',
      'P&G藤岡',
      '太陽誘電',
      'PILOT伊勢崎',
      'PILOT TCR',
      'ポッカサッポロ',
      'パナソニック大泉',
      'コダック',
      'TOPPAN　新潟',
      'タワーパートナーズセミコンダクター',
      'サンエス工業事業所',
      'デンカ青海新潟事業所',
      'ZACROSオフィス',
      'パイロット平塚',
      '新潟太陽誘電',
      'SONY DADC-Y',
      'RYOBI（菊川）',
      'シャープディスプレイカラーフィルター久居事業所',
      '凸版印刷（亀山）',
      'エルソルプロダクツ',
      '坂角',
      '楽天多摩',
      '楽天市川',
      '楽天大阪',
    ];
  } else {
    branches = [
      '',
      '札幌支店',
      '千歳支店',
      '米沢支店',
      '仙台支店',
      '郡山支店',
      '長岡支店',
      '新潟支店',
      '古河支店',
      '筑西支店',
      'つくば支店',
      '水戸支店',
      '久喜支店',
      '静岡支店',
      '名古屋支店',
      '四日市支店',
      '京都支店',
      '大阪支店',
      '難波支店',
      '神戸支店',
      '高松支店',
      '松山支店',
      '広島支店',
      '北九州支店',
      '福岡支店',
      '熊本支店',
      '宮崎支店',
      '鹿児島支店',
      '清原支店',
      '那須ファクトリー支店',
      '小山ファクトリー支店',
      '宇都宮ファクトリー支店',
      '情報の森支店',
      '那須オフィス支店',
      '小山オフィス支店',
      '宇都宮オフィス支店',
      '足利支店',
      '熊谷支店',
      '高崎支店',
      '伊勢崎支店',
      '栃木GS支店',
      '新宿支店',
      '日本橋支店',
      '東京ベイ支店',
      '立川支店',
      '横浜支店',
      '厚木支店',
      '千葉支店',
      '所沢支店',
      'さいたま支店',
      '船橋支店',
      '楽天習志野',
      'REG-A',
      '首都圏金融支店',
      'エンジニア事業部 名古屋支店',
      'エンジニア事業部 大阪支店',
      'エンジニア事業部 福岡支店',
    ];
  }

  //console.log(branches);

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor: '#0F1941',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            {activeStep === 5 ? 'おつかれさまでした' : '前給サービス申し込み'}
          </Typography>
        </Toolbar>
      </AppBar>
      {creating ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ m: 2 }}>
          {activeStep === 0 ? (
            <div>
              <div
                style={{
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    color: '#696969',
                    fontSize: '13px',
                    marginBottom: '1em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'right',
                  }}
                ></div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                ></div>
                <div
                  style={{
                    textAlign: 'center',
                    marginBottom: '2em',
                  }}
                >
                  前給サービスを初めてご利用される方へ
                </div>
                <div
                  style={{
                    border: '1px solid #E74536',
                    padding: '1em',
                    color: '#E74536',
                    marginBottom: '2em',
                  }}
                >
                  前給サービスへのお申込みは、当社で就業中の方のみご利用いただけます。現在当社で就業していない・決定していない方はお申込みいただけません。
                </div>
                <div>
                  <p>
                    こちらは前給サービス利用開始時のお手続きページです。書面・LINEのいずれかで既にご提出がお済みの方はお手続き不要です。
                  </p>
                  <p>
                    前給制度、利用可能金額枠、専用サイトの具体的な操作方法などについては前給クイックガイドをご覧ください。
                  </p>
                  <p>
                    『前給』は、きらぼし銀行の「前給システム」を利用して、ランスタッドが提供する任意の福利厚生サービスです。
                  </p>
                </div>
                <div>お手続き前に以下をご確認ください。</div>
                <br></br>
                <br></br>
                <div>
                  <div
                    style={{
                      color: '#2175D9',
                      fontWeight: 'bold',
                    }}
                  >
                    前給を使うには？
                  </div>
                  <div>
                    <p>まずは本ページよりお申込みが必要です。</p>
                    <p>
                      ご提出後、受領が完了しましたらLINEよりご連絡をさせて頂きます。
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: '#2175D9',
                      fontWeight: 'bold',
                    }}
                  >
                    前給の申込について
                  </div>
                  <div>
                    <p>
                      こちらの初回のご契約が完了後、前給の都度利用の申込が可能となります。
                    </p>
                    <p>
                      都度利用に関しては別途メニューの「前給の申請はこちらをタップ」からお手続きください。
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: '#2175D9',
                      fontWeight: 'bold',
                    }}
                  >
                    利用可能金額
                  </div>
                  <div>
                    <p>
                      勤務実績から20,000円を差し引いた残りの80％です。
                      <span
                        style={{
                          color: '#E74536',
                          fontSize: '12px',
                        }}
                      >
                        ※一部例外あり
                      </span>
                    </p>
                  </div>
                  <p>利用可能金額は専用サイトでご確認できます。</p>
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </div>
          ) : (
            ''
          )}
          {activeStep === 1 ? (
            <div>
              <div
                style={{
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    color: '#2175D9',
                    fontWeight: 'bold',
                    marginBottom: '2em',
                  }}
                >
                  担当支店を選択してください
                </div>
                <div>
                  <InputLabel
                    sx={{ ml: 0, mt: 2, color: '#0f1941', fontSize: '1em' }}
                    id="jobCategory-label"
                  >
                    {'担当支店'}
                  </InputLabel>
                  <FormControl
                    sx={{ ml: 0, mt: 1, mr: 2, mb: 2, width: '80%' }}
                  >
                    <Select
                      labelId="jobCategory-label"
                      id="jobCategory-select"
                      variant="standard"
                      value={formState.values.userBranch}
                      error={formState.values.userBranch === ''}
                      onChange={(event) => {
                        console.log(event.target.value);
                        handleChange(event, 'userBranch', event.target.value);
                      }}
                    >
                      {branches.map((branch) => {
                        return <MenuItem value={branch}>{branch}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </div>
          ) : (
            ''
          )}
          {activeStep === 2 ? (
            <span>
              <div
                style={{
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    color: '#2175D9',
                    fontWeight: 'bold',
                    marginBottom: '2em',
                    textAlign: 'center',
                  }}
                >
                  支払給与の内渡し（前給サービス）申込書
                </div>
                <div
                  style={{
                    marginBottom: '1em',
                  }}
                >
                  支払給与の内渡し（前給サービス）を申し込むにあたり、下記事項および前給契約書（支払給与の内渡契約書）を確認し、合意頂ける場合はチェックを入れてください。
                </div>
                <div>
                  <FormControl
                    required
                    variant="standard"
                    component="fieldset"
                    sx={{
                      ml: 0,
                      gap: '0.5em',
                      width: '100%',
                    }}
                  >
                    <div>
                      <FormControlLabel
                        sx={{ alignItems: 'flex-start' }}
                        control={
                          <Checkbox
                            checked={formState.values.appAgree01}
                            onChange={(event) => {
                              handleChange(
                                event,
                                'appAgree01',
                                event.target.checked,
                              );
                            }}
                            name="appAgree01"
                          />
                        }
                        label="１．前給を申し込める範囲は、当該月（一賃金計算期間）における支払賃金が２万円を超える部分の８０％の金額（１千円単位）の範囲であること"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        sx={{ alignItems: 'flex-start' }}
                        control={
                          <Checkbox
                            checked={formState.values.appAgree02}
                            onChange={(event) => {
                              handleChange(
                                event,
                                'appAgree02',
                                event.target.checked,
                              );
                            }}
                            name="appAgree02"
                          />
                        }
                        label="２．前給の申し込みは以下の通り行うこと"
                      />
                      <div
                        style={{
                          marginLeft: '20px',
                        }}
                      >
                        <div>
                          <p>
                            ※LINE前給申請受付日および前給サイト利用可能日は、締日ごとの別紙前給カレンダー参照
                          </p>
                          <ul style={{ padding: '0' }}>
                            <li
                              key="p0201"
                              style={{ listStyle: 'none', marginBottom: '1em' }}
                            >
                              （１）タイムシートを所定先までLINE送信すること（受付日午前１０時までを当日分として受付）
                            </li>
                            <li
                              key="p0202"
                              style={{ listStyle: 'none', marginBottom: '1em' }}
                            >
                              （２）「前給申込サイト」から申込手続きをすること（LINE前給申請受付日の翌日午前９時から午後４時までを当日分として受付）
                            </li>
                            <li
                              key="p0203"
                              style={{ listStyle: 'none', marginBottom: '1em' }}
                            >
                              （３）前給申込サイトからの申し込みをLINE前給申請受付日の翌平日午前９時から午後４時までに行うとその翌平日午前９時以降に前給が指定口座に振り込まれること
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div>
                      <FormControlLabel
                        sx={{ alignItems: 'flex-start' }}
                        control={
                          <Checkbox
                            checked={formState.values.appAgree03}
                            onChange={(event) => {
                              handleChange(
                                event,
                                'appAgree03',
                                event.target.checked,
                              );
                            }}
                            name="appAgree03"
                          />
                        }
                        label="３．別紙前給カレンダーの各月締日を過ぎて申し込みをしても、前給は支払われず、各月の所定の賃金支払日に支払われること"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        sx={{ alignItems: 'flex-start' }}
                        control={
                          <Checkbox
                            checked={formState.values.appAgree04}
                            onChange={(event) => {
                              handleChange(
                                event,
                                'appAgree04',
                                event.target.checked,
                              );
                            }}
                            name="appAgree04"
                          />
                        }
                        label="４．前給の支払賃金の計算は、日々の勤怠実績時間を１５分単位で集計すること"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        sx={{ alignItems: 'flex-start' }}
                        control={
                          <Checkbox
                            checked={formState.values.appAgree05}
                            onChange={(event) => {
                              handleChange(
                                event,
                                'appAgree05',
                                event.target.checked,
                              );
                            }}
                            name="appAgree05"
                          />
                        }
                        label="５．前１項にかかわらず、会社は前給の利用範囲金額の変更・中断・停止を行う場合があること"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        sx={{ alignItems: 'flex-start' }}
                        control={
                          <Checkbox
                            checked={formState.values.appAgree06}
                            onChange={(event) => {
                              handleChange(
                                event,
                                'appAgree06',
                                event.target.checked,
                              );
                            }}
                            name="appAgree06"
                          />
                        }
                        label="６．利用１回あたり、前給の利用手数料（利用者負担）がかかること"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        sx={{ alignItems: 'flex-start' }}
                        control={
                          <Checkbox
                            checked={formState.values.appAgree07}
                            onChange={(event) => {
                              handleChange(
                                event,
                                'appAgree07',
                                event.target.checked,
                              );
                            }}
                            name="appAgree07"
                          />
                        }
                        label="７．前給で支払われた金額は、最初に到来する賃金支払の金額から控除し、精算されること"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        sx={{ alignItems: 'flex-start' }}
                        control={
                          <Checkbox
                            checked={formState.values.appAgree08}
                            onChange={(event) => {
                              handleChange(
                                event,
                                'appAgree08',
                                event.target.checked,
                              );
                            }}
                            name="appAgree08"
                          />
                        }
                        label={
                          <span>
                            ８．上記の他、別紙「
                            <a
                              href="https://hub.randstad.co.jp/hubfs/LINE/LINE前給|支払給与の内渡し(前給サービス)申込書_前給申込書_CPC運用版(2022年3月~).pdf"
                              target="_blank"
                            >
                              前給クイックガイド
                            </a>
                            」の通りであること
                          </span>
                        }
                      />
                    </div>
                    <hr
                      style={{
                        borderStyle: 'dotted none none none',
                        width: '100%',
                        color: '#0F1941',
                        borderWidth: '4px',
                        marginTop: '20px',
                        marginBottom: '20px',
                      }}
                    />
                    <div>
                      <FormControlLabel
                        sx={{ alignItems: 'flex-start' }}
                        control={
                          <Checkbox
                            checked={formState.values.ContractAgree01}
                            disabled={!formState.values.isContractAgreePopUped}
                            onChange={(event) => {
                              handleChange(
                                event,
                                'ContractAgree01',
                                event.target.checked,
                              );
                            }}
                            name="ContractAgree01"
                          />
                        }
                        label={
                          <span>
                            <Link
                              onClick={() => {
                                setOpenContract(true);
                              }}
                            >
                              前給契約書（支払給与の内渡契約書）
                            </Link>
                            を確認し、合意しました
                          </span>
                        }
                      />
                      <div
                        style={{
                          color: '#E74536',
                          paddingTop: '8px',
                        }}
                      >
                        ※前給契約書のリンクをタップして内容を確認の上チェックを入れてください
                      </div>
                    </div>
                  </FormControl>
                </div>
                <div>
                  <MobileDatePicker
                    label="申請日"
                    inputFormat="yyyy/MM/dd"
                    toolbarFormat="yyyy/MM/dd"
                    value={formState.values.appDate}
                    minDate={new Date()}
                    onChange={(date) => {
                      console.log(date);
                      handleChange(
                        {
                          target: {
                            name: 'appDate',
                          },
                        },
                        'appDate',
                        moment(date).format('YYYY-MM-DD'),
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '92%' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={formState.values.appDate == ''}
                        {...params}
                      />
                    )}
                  />
                  {/*
                  <TextField
                    id="filled-basic"
                    variant="standard"
                    label="申込日"
                    type="date"
                    placeholder=""
                    value={formState.values.appDate}
                    error={formState.values.appDate === ''}
                    inputProps={{ maxLength: '8' }}
                    onChange={(event) => {
                      console.log(event.target.value);
                      handleChange(event, 'appDate', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '92%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  */}
                </div>
                <div>
                  <TextField
                    id="staff-code"
                    variant="standard"
                    label="スタッフコード"
                    type="tel"
                    placeholder="00000000"
                    value={formState.values.staffCode}
                    error={formState.values.staffCode === ''}
                    inputProps={{ maxLength: '8' }}
                    onChange={(event) => {
                      //console.log(event.target.value);
                      handleChange(event, 'staffCode', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '92%' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div>
                  <TextField
                    id="standard-basic"
                    label="名前"
                    variant="standard"
                    type="text"
                    value={formState.values.userName}
                    error={formState.values.userName === ''}
                    onChange={(event) => {
                      handleChange(event, 'userName', event.target.value);
                    }}
                    sx={{ mt: 2, ml: 2, mr: 2, mb: 2, width: '92%' }}
                    placeholder="山田太郎"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div
                  style={{
                    color: '#E74536',
                  }}
                >
                  ※スタッフコード・申込者氏名に訂正がある場合は入力欄をタップすると修正が可能です。入力内容に誤りがある場合、再提出となる場合がございます。
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 3 ? (
            <span>
              <div
                style={{
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    color: '#2175D9',
                    fontWeight: 'bold',
                    marginBottom: '2em',
                    textAlign: 'center',
                  }}
                >
                  前給の振込口座の確認
                </div>
                <div>
                  <p
                    style={{
                      color: '#E74536',
                    }}
                  >
                    ～必ずご確認ください～
                  </p>
                  前給のお振込先は原則給与振込口座となります。
                  <br />
                  ※ご入社の際、事前にご登録いただいた給与振り込み口座へのお振込みとなります。
                </div>
                <div
                  style={{
                    marginTop: '1em',
                    marginBottom: '1em',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formState.values.bankAccountAgree01}
                        onChange={(event) => {
                          handleChange(
                            event,
                            'bankAccountAgree01',
                            event.target.checked,
                          );
                        }}
                        name="bankAccountAgree01"
                      />
                    }
                    label="前給給与の振り込み先について理解しました"
                  />
                </div>
                <div
                  style={{
                    border: '1px dotted #2175D9',
                    padding: '16px 16px 16px 16px',
                    margin: '32px 0px 32px 0px',
                    color: '#2175D9',
                  }}
                >
                  <p>●入社手続き時に給与口座申請がまだの方</p>
                  <p>●入社後にまだお給与のお受け取りをされていない方</p>
                </div>
                <div>
                  前給振込までにお口座登録がない場合、お振込みに間に合わない場合がございます。
                  こちらの申込が完了後、
                  <span
                    style={{
                      textDecoration: 'underline',
                    }}
                  >
                    LINEトーク画面上
                  </span>
                  に給与口座のご登録ページをご案内させていただきます。必ず口座登録を行ってください。
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 4 ? (
            <span>
              <div
                style={{
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                <div
                  style={{
                    color: '#2175D9',
                    fontWeight: 'bold',
                    marginBottom: '2em',
                    textAlign: 'center',
                  }}
                >
                  申込内容の確認ページ
                </div>
                <div
                  style={{
                    margin: '0px 0px 32px 0',
                  }}
                >
                  <div
                    style={{
                      margin: '16px 0px',
                      fontWeight: '600',
                    }}
                  >
                    申込書・契約書
                  </div>
                  <div>
                    <div
                      style={{
                        margin: '16px 0px',
                        padding: '4px 0px',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      支払給与の内渡し（前給サービス）申込書 ：
                      <span>{formState.values.appAgree}</span>
                    </div>
                    <div
                      style={{
                        margin: '16px 0px',
                        padding: '4px 0px',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      前給契約書（支払給与の内渡契約書）：
                      <span>{formState.values.contractAgree}</span>
                    </div>
                    <div
                      style={{
                        margin: '16px 0px',
                        padding: '4px 0px',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      口座に関すること：
                      <span>{formState.values.bankAccountAgree}</span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    margin: '0px 0px 32px 0px',
                  }}
                >
                  <div
                    style={{
                      margin: '16px 0px',
                      fontWeight: '600',
                    }}
                  >
                    申込者情報
                  </div>
                  <div>
                    <div
                      style={{
                        margin: '16px 0px',
                        padding: '4px 0px',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      申込日：
                      <span>{formState.values.appDate.replace(/-/g, '/')}</span>
                    </div>
                    <div
                      style={{
                        margin: '16px 0px',
                        padding: '4px 0px',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      スタッフコード：
                      <span>{formState.values.staffCode}</span>
                    </div>
                    <div
                      style={{
                        margin: '16px 0px',
                        padding: '4px 0px',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      申込者氏名：
                      <span>{formState.values.userName}</span>
                    </div>
                    <div
                      style={{
                        margin: '16px 0px',
                        padding: '4px 0px',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      担当支店：
                      {formState.values.userBranch}
                    </div>
                  </div>
                </div>
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep === 5 ? (
            <span>
              <div
                style={{
                  border: '1px solid rgba(215,215,215,1)',
                  padding: '1em',
                  // marginTop: '1em',
                }}
              >
                おつかれさまでした。
                <br />
                <br />
                こちらのページでお申し込みは完了となります。
                <br />
                受領完了後、LINEより完了のメッセージをお送りいたしますので今しばらくお待ちください。
                <br />
                本手続きでご提出いただいたお申込書およびご契約書の内容については、LINEメニューの「前給に関する資料集」よりいつでもご確認頂けます。
                <br />
                尚、本件に関するお問合せについては担当支店までご連絡下さい。
                <br />
              </div>
              <div
                style={{
                  border: '1px dotted #2175D9',
                  padding: '16px 16px 16px 16px',
                  margin: '32px 0px 32px 0px',
                  color: '#2175D9',
                }}
              >
                <p>●入社手続き時に給与口座申請がまだの方</p>
                <p>●入社後にまだお給与のお受け取りをされていない方</p>
              </div>
              <div>
                前給振込までにお口座登録がない場合、お振込みに間に合わない場合がございます。
                こちらの申込が完了後、
                <span
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  LINEトーク画面上
                </span>
                に給与口座のご登録ページをご案内させていただきます。必ず口座登録を行ってください。
              </div>
              <Box sx={{ mt: 9 }} />
            </span>
          ) : (
            ''
          )}
          {activeStep < 5 ? (
            <Grid
              item
              xs={7}
              style={{
                textAlign: 'center',
              }}
            >
              <AppBar
                position="fixed"
                color="primary"
                sx={{
                  top: 'auto',
                  bottom: -2,
                  backgroundColor: '#2175D9',
                }}
              >
                <Grid
                  container
                  style={{
                    height: 55,
                  }}
                >
                  {activeStep > 0 && activeStep < 4 ? (
                    <React.Fragment>
                      <Grid
                        item
                        xs={6}
                        style={{
                          backgroundColor: '#d9d9d9',
                          textAlign: 'center',
                        }}
                      >
                        <Button
                          color="inherit"
                          aria-label="back key"
                          sx={{
                            backgroundColor: '#d9d9d9',
                            marginTop: 1,
                            width: '100%',
                            color: '#1d6ad4',
                            flexGrow: 1,
                          }}
                          //disabled={activeStep === 0 || activeStep === 6}
                          onClick={handleBack}
                        >
                          <span
                            style={{ fontSize: '16px', fontWeight: 'bold' }}
                          >
                            前へ
                          </span>
                        </Button>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <Button
                          color="inherit"
                          aria-label="next key"
                          sx={{
                            marginTop: 1,
                            flexGrow: 1,
                          }}
                          onClick={handleNext}
                        >
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                            }}
                          >
                            {activeStep === 0
                              ? '申し込みへすすむ'
                              : activeStep === 1
                              ? '申し込みへすすむ'
                              : activeStep === 2
                              ? '次へ'
                              : activeStep === 3
                              ? '入力内容の確認'
                              : ''}
                          </span>
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  {activeStep === 0 ? (
                    <React.Fragment>
                      <Grid
                        item
                        sx={{ width: '100%' }}
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <Button
                          color="inherit"
                          aria-label="next key"
                          sx={{
                            marginTop: 1,
                            flexGrow: 1,
                          }}
                          onClick={handleNext}
                        >
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                            }}
                          >
                            申し込みへすすむ
                          </span>
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  {activeStep === 4 ? (
                    <React.Fragment>
                      <Grid
                        item
                        xs={6}
                        style={{
                          backgroundColor: '#d9d9d9',
                          textAlign: 'center',
                        }}
                      >
                        <Button
                          color="inherit"
                          aria-label="back key"
                          sx={{
                            backgroundColor: '#d9d9d9',
                            marginTop: 1,
                            width: '100%',
                            color: '#1d6ad4',
                            flexGrow: 1,
                          }}
                          //disabled={activeStep === 0 || activeStep === 6}
                          onClick={handleBack}
                        >
                          <span
                            style={{ fontSize: '16px', fontWeight: 'bold' }}
                          >
                            前へ
                          </span>
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <Button
                          color="inherit"
                          aria-label="next key"
                          sx={{
                            marginTop: 1,
                            flexGrow: 1,
                          }}
                          onClick={() => {
                            setopenConfirmPost(true);
                          }}
                        >
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                            }}
                          >
                            前給サービスへ申し込む
                          </span>
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </Grid>
                {/*</Toolbar>*/}
              </AppBar>
            </Grid>
          ) : (
            ''
          )}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                担当支店を選択してください。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open2}
            onClose={handleClose2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'エラー'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {viewMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose2} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openConfirmPost}
            onClose={handleCloseConfirmPost}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'登録内容に間違いなければ登録してください。'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText></DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmPost} autoFocus>
                戻る
              </Button>
              <Button onClick={submit} autoFocus>
                登録
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openContract}
            onClose={handleCloseContract}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              前給契約書（支払給与の内渡契約書）
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div>
                  ランスタッド株式会社を甲、利用者を乙とし、当事者間において次の通り前給（支払給与の内渡）契約を締結する。
                  <br />
                  第１条（内渡の方法）
                  <br />
                  （１）甲は、きらぼしテック株式会社(以下｢銀行｣といいます。)が提供する、前給システム（以下「前給」といいます。）を利用して、乙から支払給与の内渡の申込があった場合には、乙の預金口座に振込む方法により、別途甲乙間で締結された労働者派遣雇用契約及び雇用契約（以下「雇用契約」といいます。）に基づく報酬の支払給与（以下「支払給与」といいます。）を内渡すものとします。
                  <br />
                  （２）前項の内渡金の発生時期は、銀行が本条の振込金を発信した時とします。
                  <br />
                  （３）前２項による支払給与の内渡の極度額は、次の金額を限度とします。
                  <br />
                  　　①第１項の支払給与を超えず、かつ、甲が定める所定の金額（以下｢限度額｣といいます。のうちいずれか少ない金額
                  <br />
                  　　②前号の限度額は、甲の判断により乙に通知することなくいつでも変更することができるものとします。
                  <br />
                  （４）乙は、甲が定める方法により前給を利用するものとします。なお、乙はパスワード等を第三者に知られないよう管理する責任を負うものとし、甲が定める本人確認方法により乙本人からの依頼として取り扱いを受け付けたうえは、パスワード等に偽造、変造、盗用その他の事故があっても、それによって生じた損害について、甲の責めに帰すべき場合を除き、甲は責任を負わないものとします。
                  <br />
                  （５）甲は、乙から支払給与の内渡の申し込みがあった場合であっても、乙の信用状態により、自己の裁量をもって支払給与の内渡の実行を行わないことができ、乙はこれに対し異議を述べないものとします。
                  <br />
                  第２条（返済の方法）
                  <br />
                  第１条の支払給与の内渡分の精算日は、内渡日以降、最初に到来する支払給与の支払予定日(以下｢支払予定日｣といいます。)に、乙に支払うべき支払給与の金額の中から、乙が当該支払予定日に返済すべき内渡残高を控除する方法で行うものとします。
                  <br />
                  第３条（前給の問合せ窓口）
                  <br />
                  乙は、前給の取扱についての問合せについては、甲に対してのみ行うことができるものとします。
                  <br />
                  第４条（情報利用）
                  <br />
                  乙は、乙の甲における支払給与額・内渡情報・住所・氏名等の情報が、前給の利用および利用案内のために同サービスを取扱うきらぼしテック株式会社(同行が前給に関する業務を委託する業者を含みます)によって共有されることに同意します。
                  <br />
                  第５条（契約期間）
                  <br />
                  （１）本契約の有効期間は、契約締結日より１年間とします。ただし、本契約の期間満了の１か月前までに甲乙何れからも契約終了の意思表示のない限り、本契約は更に１年間延長され、以降も同様とします。
                  <br />
                  （２）前項に関わらず、乙が甲に提供した登録情報の削除依頼を申し出た場合には、当該情報の削除日をもって本契約も終了するものとします。
                  <br />
                  第６条（管轄）
                  <br />
                  本契約に関し紛争が生じた場合、甲乙は、東京地方裁判所及び東京簡易裁判所を第一審の専属的管轄裁判所とすることに合意します。
                  <br />
                  第７条（災害等による免責）
                  <br />
                  次の各号の事由により、本契約の取り扱いに遅延、不能等があっても、これによって生じた乙の損害については、甲はその責を負わないものとします。
                  <br />
                  （１）災害・事変・裁判所等公的機関の措置等のやむおえない事由があったとき
                  <br />
                  （２）甲または金融機関の共同システムの運営自体が相当の安全対策を講じたにも関わらず、端末機、通信回線またはコンピューター等に障害が生じたとき
                  <br />
                  （３）甲以外の金融機関の責に帰すべき事由があったとき
                  <br />
                  （４）その他、甲の責に帰すことのできない事由があったとき
                  <br />
                  第８条（協議事項）
                  <br />
                  本契約に定めのない事項及び本契約の条項の解釈につき疑義を生じた事項については、甲乙協議の上、円満に解決するものとします。
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseContract} autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </span>
  );
};

export default AdvancePayEntryForm;
