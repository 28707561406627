import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Feed from './routes/feed';
import Profile from './routes/profile';
import Pins from './routes/pins';
import Detail from './routes/detail';
import Applications from './routes/applications';
import ApplicationDetail from './routes/applicationDetail';
import ProfessionalFeed from './routes/professionalFeed';
import ProfessionalPins from './routes/professionalPins';
import ProfessionalDetail from './routes/professionalDetail';
import ProfessionalProfile from './routes/professionalProfile';

import SpotFeed from './routes/spotFeed';
import SpotProfile from './routes/spotProfile';

import liff from '@line/liff';
import SafetyConfirmation from './routes/safetyConfirmation';

import StaffingAttendance from './routes/staffingAttendance';
import InHouseAttendance from './routes/inhouseAttendance';
import Entry from './routes/entry';
import SocialInsurance from './routes/socialInsurance';

import Identification from './routes/identification';
import AdvancePay from './routes/advancePay';
import AdvancePayEntry from './routes/advancePayEntry';
import Complaint from './routes/complaint';

// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Liquid from './routes/liquid';
import Training from './routes/training';
import Top from './routes/top';
import ContractRenewal from './routes/contractRenewal';
import PeriodicSurvey from './routes/periodicSurvey';

const PROFILE_LIFF_ID = process.env.REACT_APP_PROFILE_LIFF_ID || '';
const PINS_LIFF_ID = process.env.REACT_APP_PINS_LIFF_ID || '';
const APPLICATIONS_LIFF_ID = process.env.REACT_APP_APPLICATIONS_LIFF_ID || '';
const FEED_LIFF_ID = process.env.REACT_APP_FEED_LIFF_ID || '';
const JOB_DETAIL_LIFF_ID = process.env.REACT_APP_JOB_DETAIL_LIFF_ID || '';
const PROFILE_2_LIFF_ID = process.env.REACT_APP_PROFILE_2_LIFF_ID || '';
const PINS_2_LIFF_ID = process.env.REACT_APP_PINS_2_LIFF_ID || '';
const APPLICATIONS_2_LIFF_ID =
  process.env.REACT_APP_APPLICATIONS_2_LIFF_ID || '';
const FEED_2_LIFF_ID = process.env.REACT_APP_FEED_2_LIFF_ID || '';
const JOB_DETAIL_2_LIFF_ID = process.env.REACT_APP_JOB_DETAIL_2_LIFF_ID || '';

const PROFESSIONAL_PROFILE_LIFF_ID =
  process.env.REACT_APP_PROFESSIONAL_PROFILE_LIFF_ID || '';
const PROFESSIONAL_PINS_LIFF_ID =
  process.env.REACT_APP_PROFESSIONAL_PINS_LIFF_ID || '';
const PROFESSIONAL_FEED_LIFF_ID =
  process.env.REACT_APP_PROFESSIONAL_FEED_LIFF_ID || '';
const PROFESSIONAL_JOB_DETAIL_LIFF_ID =
  process.env.REACT_APP_PROFESSIONAL_JOB_DETAIL_LIFF_ID || '';

const SPOT_PROFILE_LIFF_ID = process.env.REACT_APP_SPOT_PROFILE_LIFF_ID || '';
const SPOT_FEED_LIFF_ID = process.env.REACT_APP_SPOT_FEED_LIFF_ID || '';

const SAFETY_CONFIRMATION_LIFF_ID =
  process.env.REACT_APP_SAFETY_CONFIRMATION_LIFF_ID || '';

const ATTENDANCE_LIFF_ID = process.env.REACT_APP_ATTENDANCE_LIFF_ID || '';
const ATTENDANCE_2_LIFF_ID = process.env.REACT_APP_ATTENDANCE_2_LIFF_ID || '';
const ENTRY_LIFF_ID = process.env.REACT_APP_ENTRY_LIFF_ID || '';
const ENTRY_2_LIFF_ID = process.env.REACT_APP_ENTRY_2_LIFF_ID || '';
const LIQUID_LIFF_ID = process.env.REACT_APP_LIQUID_LIFF_ID || '';
const LIQUID_2_LIFF_ID = process.env.REACT_APP_LIQUID_2_LIFF_ID || '';

const SOCIAL_INSURANCE_LIFF_ID =
  process.env.REACT_APP_SOCIAL_INSURANCE_LIFF_ID || '';
const SOCIAL_INSURANCE_2_LIFF_ID =
  process.env.REACT_APP_SOCIAL_INSURANCE_2_LIFF_ID || '';

const IDENTIFICATION_LIFF_ID =
  process.env.REACT_APP_IDENTIFICATION_LIFF_ID || '';
const IDENTIFICATION_2_LIFF_ID =
  process.env.REACT_APP_IDENTIFICATION_2_LIFF_ID || '';

const ADVANCE_PAY_LIFF_ID = process.env.REACT_APP_ADVANCE_PAY_LIFF_ID || '';
const ADVANCE_PAY_2_LIFF_ID = process.env.REACT_APP_ADVANCE_PAY_2_LIFF_ID || '';

const ADVANCE_PAY_ENTRY_LIFF_ID =
  process.env.REACT_APP_ADVANCE_PAY_ENTRY_LIFF_ID || '';
const ADVANCE_PAY_ENTRY_2_LIFF_ID =
  process.env.REACT_APP_ADVANCE_PAY_2_ENTRY_LIFF_ID || '';

const COMPLAINT_LIFF_ID = process.env.REACT_APP_COMPLAINT_LIFF_ID || '';
const COMPLAINT_2_LIFF_ID = process.env.REACT_APP_COMPLAINT_2_LIFF_ID || '';

const TRAINING_LIFF_ID = process.env.REACT_APP_TRAINING_LIFF_ID || '';
const TRAINING_2_LIFF_ID = process.env.REACT_APP_TRAINING_2_LIFF_ID || '';

const TOP_LIFF_ID = process.env.REACT_APP_TOP_LIFF_ID || '';
const TOP_LIFF_2_ID = process.env.REACT_APP_TOP_2_LIFF_ID || '';

const CONTRACT_RENEWAL_LIFF_ID =
  process.env.REACT_APP_CONTRACT_RENEWAL_LIFF_ID || '';
const CONTRACT_RENEWAL_2_LIFF_ID =
  process.env.REACT_APP_CONTRACT_RENEWAL_2_LIFF_ID || '';

const PERIODIC_SURVEY_LIFF_ID =
  process.env.REACT_APP_PERIODIC_SURVEY_LIFF_ID || '';

const liffId = location.pathname.includes('profile_2')
  ? PROFILE_2_LIFF_ID
  : location.pathname.includes('advance-pay-entry')
  ? ADVANCE_PAY_ENTRY_LIFF_ID
  : location.pathname.includes('pins_2')
  ? PINS_2_LIFF_ID
  : location.pathname.includes('applications_2')
  ? APPLICATIONS_2_LIFF_ID
  : location.pathname.includes('detail_2')
  ? JOB_DETAIL_2_LIFF_ID
  : location.pathname.includes('attendance-report/2')
  ? ATTENDANCE_2_LIFF_ID
  : location.pathname.includes('entry/2')
  ? ENTRY_2_LIFF_ID
  : location.pathname.includes('liquid/2')
  ? LIQUID_2_LIFF_ID
  : location.pathname.includes('social-insurance/2')
  ? SOCIAL_INSURANCE_2_LIFF_ID
  : location.pathname.includes('identification/2')
  ? IDENTIFICATION_2_LIFF_ID
  : location.pathname.includes('advance-pay/2')
  ? ADVANCE_PAY_2_LIFF_ID
  : location.pathname.includes('advance-pay-entry/2')
  ? ADVANCE_PAY_ENTRY_2_LIFF_ID
  : location.pathname.includes('top/2')
  ? TOP_LIFF_2_ID
  : location.pathname.includes('complaint/2')
  ? COMPLAINT_2_LIFF_ID
  : location.pathname.includes('training/2')
  ? TRAINING_2_LIFF_ID
  : location.pathname.includes('2')
  ? FEED_2_LIFF_ID
  : location.pathname.includes('professional/profile')
  ? PROFESSIONAL_PROFILE_LIFF_ID
  : location.pathname.includes('professional/pins')
  ? PROFESSIONAL_PINS_LIFF_ID
  : location.pathname.includes('professional/detail')
  ? PROFESSIONAL_JOB_DETAIL_LIFF_ID
  : location.pathname.includes('professional')
  ? PROFESSIONAL_FEED_LIFF_ID
  : location.pathname.includes('spot/profile')
  ? SPOT_PROFILE_LIFF_ID
  : location.pathname.includes('spot')
  ? SPOT_FEED_LIFF_ID
  : location.pathname.includes('profile')
  ? PROFILE_LIFF_ID
  : location.pathname.includes('pins')
  ? PINS_LIFF_ID
  : location.pathname.includes('applications')
  ? APPLICATIONS_LIFF_ID
  : location.pathname.includes('detail')
  ? JOB_DETAIL_LIFF_ID
  : location.pathname.includes('safety-confirmation')
  ? SAFETY_CONFIRMATION_LIFF_ID
  : location.pathname.includes('attendance-report')
  ? ATTENDANCE_LIFF_ID
  : location.pathname.includes('entry')
  ? ENTRY_LIFF_ID
  : location.pathname.includes('liquid')
  ? LIQUID_LIFF_ID
  : location.pathname.includes('social-insurance')
  ? SOCIAL_INSURANCE_LIFF_ID
  : location.pathname.includes('identification')
  ? IDENTIFICATION_LIFF_ID
  : location.pathname.includes('advance-pay')
  ? ADVANCE_PAY_LIFF_ID
  : location.pathname.includes('complaint')
  ? COMPLAINT_LIFF_ID
  : location.pathname.includes('training')
  ? TRAINING_LIFF_ID
  : location.pathname.includes('contract-renewal')
  ? CONTRACT_RENEWAL_LIFF_ID
  : location.pathname.includes('contract-renewal/2')
  ? CONTRACT_RENEWAL_2_LIFF_ID
  : location.pathname.includes('periodic-survey')
  ? PERIODIC_SURVEY_LIFF_ID
  : location.pathname.includes('top')
  ? TOP_LIFF_ID
  : FEED_LIFF_ID;

const theme = createTheme({
  palette: {
    primary: {
      main: '#1d6ad4',
    },
  },
});

liff
  .init({
    liffId,
  })
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            {/*<LocalizationProvider dateAdapter={AdapterMoment}>*/}
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ja}
              dateFormats={{ monthAndYear: 'yyyy年M月' }}
              localeText={{
                cancelButtonLabel: 'キャンセル',
                okButtonLabel: '選択',
              }}
            >
              <BrowserRouter>
                <Routes>
                  {/* Full-time Staffing */}
                  <Route path="/" element={<Feed />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="pins" element={<Pins />} />
                  <Route path="detail/:number" element={<Detail />} />
                  <Route path="detail" element={<Detail />} />
                  <Route path="applications" element={<Applications />} />
                  <Route
                    path="application/:number"
                    element={<ApplicationDetail />}
                  />

                  {/* Full-time In-house */}
                  <Route path="/2" element={<Feed />} />
                  <Route path="profile_2" element={<Profile />} />
                  <Route path="pins_2" element={<Pins />} />
                  <Route path="detail_2/:number" element={<Detail />} />
                  <Route path="detail_2" element={<Detail />} />
                  <Route path="applications_2" element={<Applications />} />
                  <Route
                    path="application_2/:number"
                    element={<ApplicationDetail />}
                  />

                  {/* Professional */}
                  <Route path="professional" element={<ProfessionalFeed />} />
                  <Route
                    path="professional/profile"
                    element={<ProfessionalProfile />}
                  />
                  <Route
                    path="professional/pins"
                    element={<ProfessionalPins />}
                  />
                  <Route
                    path="professional/detail/:number"
                    element={<ProfessionalDetail />}
                  />
                  <Route
                    path="professional/detail"
                    element={<ProfessionalDetail />}
                  />

                  {/* SPOT */}
                  <Route path="spot" element={<SpotFeed />} />
                  <Route path="spot/profile" element={<SpotProfile />} />

                  {/* Safety Confirmation */}
                  <Route
                    path="safety-confirmation"
                    element={<SafetyConfirmation />}
                  />

                  {/* Attendance Report  Staffing */}
                  <Route
                    path="attendance-report"
                    element={<StaffingAttendance />}
                  />
                  {/* Attendance Report  In-house */}
                  <Route
                    path="attendance-report/2"
                    element={<InHouseAttendance />}
                  />

                  {/* Entry  Staffing */}
                  <Route path="entry" element={<Entry />} />
                  {/* Entry  In-house */}
                  <Route path="entry/2" element={<Entry />} />
                  {/* Entry - Liquid  Staffing */}
                  <Route path="liquid" element={<Liquid />} />
                  {/* Entry - Liquid In-house */}
                  <Route path="liquid/2" element={<Liquid />} />

                  {/* Social insurance Staffing */}
                  <Route
                    path="social-insurance"
                    element={<SocialInsurance />}
                  />
                  {/* Social insurance In-house */}
                  <Route
                    path="social-insurance/2"
                    element={<SocialInsurance />}
                  />

                  {/* Identification  Staffing */}
                  <Route path="identification" element={<Identification />} />
                  {/* Identification  In-house */}
                  <Route path="identification/2" element={<Identification />} />

                  {/* Advance pay  Staffing */}
                  <Route path="advance-pay" element={<AdvancePay />} />
                  {/* Advance pay  In-house */}
                  <Route path="advance-pay/2" element={<AdvancePay />} />

                  {/* Advance pay  Staffing */}
                  <Route
                    path="advance-pay-entry"
                    element={<AdvancePayEntry />}
                  />

                  {/* Complaint  Staffing */}
                  <Route path="complaint" element={<Complaint />} />
                  {/* Complaint  In-house */}
                  <Route path="complaint/2" element={<Complaint />} />

                  {/* Training  In-house */}
                  <Route path="training" element={<Training />} />
                  {/* Training  Staffing */}
                  <Route path="training/2" element={<Training />} />

                  <Route
                    path="contract-renewal"
                    element={<ContractRenewal />}
                  />
                  <Route
                    path="contract-renewal/2"
                    element={<ContractRenewal />}
                  />

                  <Route path="periodic-survey" element={<PeriodicSurvey />} />

                  {/* Top  Staffing */}
                  <Route path="top" element={<Top />} />
                  {/* Top  In-house */}
                  <Route path="top/2" element={<Top />} />
                </Routes>
              </BrowserRouter>
            </LocalizationProvider>
          </Provider>
        </ThemeProvider>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  });
